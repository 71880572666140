<template>
  <div class="" v-wechat-title="region_infos?region_infos.RegionName+'投资地图':'投资地图'">
    <nav-bar :title="region_infos?region_infos.RegionName+'投资地图':'投资地图'" />
    <baidu-map
      :center="center"
      :zoom="zoom"
      @ready="handler"
      :max-zoom="maxZoom"
      :min-zoom="minZoom"
      class="bm-view"
      ak="R9Z98XEvB0uWLHGRT4YLlrGo0h5Ey9BP"
    >
      <!-- 多边形 -->
      <bm-polygon
        v-for="(item, index) in polygons"
        :key="item.id"
        :path="item.points"
        :strokeColor="item.strokeColor"
        :stroke-opacity="0.8"
        :fillColor="item.fillColor"
        :stroke-weight="item.strokeWidth"
        :editing="false"
      />
      <!-- 行政区划 -->
      <bm-boundary
        v-if="isXZQU"
        :name="boundary.name"
        :strokeWeight="2"
        :strokeColor="boundary.strokeColor"
        :fillOpacity="boundary.fillOpacity"
        :fillColor="'rgba(255,255,255,0)'"
        :massClear="false"
      ></bm-boundary>
      <!-- 缩小放大控件 -->
      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>
      <!-- 标记点 -->
      <bm-marker-clusterer :averageCenter="true">
              <bm-marker
        v-for="item in markers"
        :key="item.id"
        @click="markerClick(item)"
        :position="{ lng: item.longitude, lat: item.latitude }"
        :icon="{
          url: require('@/assets/images/location.png'),
          size: { width: 30, height: 30 },
        }"
      >
        <bm-label
          :content="item.name"
          @click="markerClick(item)"
          :labelStyle="{
            color: '#35a3bd',
            fontSize: '.8rem',
            borderColor: '#35a3bd',
          }"
          :offset="{ width: -30, height: 30 }"
        />
      </bm-marker>
   </bm-marker-clusterer>
    </baidu-map>
    <!-- 头部区域 -->
    <div class="topnav" style="background-color: #fff">
      <div
        class="topnav_item"
        @click="navIndexClicl(item, index)"
        v-for="(item, index) in allList"
        :key="item.id"
        :class="navIndex == index ? 'actived' : ''"
      >
        <div class="topnav_items">
          <img :src="item.icon" />
          <div class="font">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!-- 左侧按钮区域 -->
    <div class="left_btn">
      <span
        class="text"
        :class="massifIndex === index ? 'activeds' : ''"
        v-for="(item, index) in muList"
        @click="viewMassif(index)"
        :key="item.id"
        >{{ item.txt }}</span
      >
    </div>
    <!-- 折叠框 -->
    <div class="collapse" v-if="flags">
      <div
        class="collapse_item"
        style="position: relative"
        @click="muClick(indexs)"
        v-for="(item, indexs) in allList"
        :key="item.id"
      >
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: left;
            width: 100%;
          "
        >
          <img :src="item.icon" />
          <div>
            {{ item.name }}
            <span @click="more">显示更多</span>
          </div>
        </div>
        <div
          v-if="xqIndex == indexs ? true : false"
          style="width: 100%; margin-top: 10rpx"
        >
          <div
            @click.stop="muItemClick(items)"
            v-for="items in catalogueList"
            :key="items.id"
            class="collapse_item_list"
            style="white-space: pre-wrap"
          >
            {{ items.name }}
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="show" closeable round position="bottom">
      <div class="popup_center">
        <span class="title">{{ zancun.name }}</span>
        <div class="dflex_row_center" style="justify-content: space-around">
          <div class="popup_btn" v-if="!massifFlag" @click="toDetail">
            详情页
          </div>
          <div class="popup_btn" v-if="zancun.relation_landid && zancun.relation_landid!=null" @click="toProjectDetail">
            详情页
          </div>
          <div class="popup_btn" @click="navigation">去导航</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import navBar from "@/components/nav/nav";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmNavigation from "vue-baidu-map/components/controls/Navigation";
import BmMarker from "vue-baidu-map/components/overlays/Marker";
import BmLabel from "vue-baidu-map/components/overlays/Label";
import BmBoundary from "vue-baidu-map/components/others/Boundary";
import BmPolygon from "vue-baidu-map/components/overlays/Polygon";
import BmMarkerClusterer from 'vue-baidu-map/components/extra/MarkerClusterer'
import {
  region_info,
  project_list,
  mapimg_list,
  land_map_list,
} from "@/api/http";
import { Toast, Popup } from "vant";
export default {
  data() {
    return {
      title: "地图",
      params: null,
      center: { lng: 0, lat: 0 },
      zoom: 3,
      b_Map: null,
      map: null,
      maxZoom: 20,
      minZoom: 8,
      region_infos: null,
      show: false,
      url: ['gardenlist', 'landlist', 'workslist', 'itemslist', 'buildlist', 'masslist','more_enterprise','more_business'],
      //头部导航栏数据
      navIndex: 0,
      allList: [
        {
          id: 1,
          name: "园区",
          icon: require("@/assets/images/yuanqu.png"),
        },
        {
          id: 2,
          name: "地块",
          icon: require("@/assets/images/dikuai.png"),
        },
        {
          id: 3,
          name: "厂房",
          icon: require("@/assets/images/changfang.png"),
        },
        {
          id: 4,
          name: "项目",
          icon: require("@/assets/images/xiangmu.png"),
        },
        {
          id: 5,
          name: "楼宇",
          icon: require("@/assets/images/louyu.png"),
        },
        {
          id: 6,
          name: "众创",
          icon: require("@/assets/images/zhongchuang.png"),
        },
        {
          id: 7,
          name: "名企",
          icon: require("@/assets/images/qiye.png"),
        },
        {
          id: 8,
          name: "商协会",
          icon: require("@/assets/images/sxh.png"),
        },
      ],
      catalogueList: [],
      markers: [],
      massifIndex: -1,
      isXZQU: false,
      zancun: {},
      boundary: {
        name: "",
        strokeColor: "#000",
        fillOpacity: 2,
        fillColor: "none",
      },
      //目录板块
      muList: [
        {
          id: 1,
          txt: "地块展示",
        },
        {
          id: 2,
          txt: "目录",
        },
      ],
      xqIndex: -1,
      flags: false,
      massifFlag: false,
      page: 1,
      polygons: [],
      mapimg_lists: [],
      land_mapimg_lists: [],
      toID: ''
    };
  },

  components: {
    navBar,
    BaiduMap,
    BmNavigation,
    BmMarker,
    BmLabel,
    BmBoundary,
    BmPolygon,
    BmMarkerClusterer,
    [Popup.name]: Popup,
  },
  methods: {
    handler({ BMap, map }) {
      this.b_Map = BMap;
      this.map = map;
      this.mapInfo();
    },
    //地图导航栏点击事件
    navIndexClicl(item, index) {
      this.navIndex = index;
      this.page = 1;
      this.catalogueList = [];
      this.massifIndex = -1;
      this.flags = false;
      this.massifFlag = false;
      this.isXZQU = true;
      this.loadList();
    },
    loadList() {
      this.polygons = [];
      project_list(
        {
          region_code: this.params.code,
          page: this.page,
        },
        this.url[this.navIndex]
      ).then((res) => {
        var data = [];
        if (this.allList[this.navIndex].name == "园区") {
          data = res.data.gardens.data;
          if (data.length > 0) {
            data.forEach((v) => {
              this.catalogueList.push({
                id: v.id,
                name: v.garden_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          } else {
            Toast("没有更多了");
          }
        } else if (this.allList[this.navIndex].name == "地块") {
          data = res.data.land.data;
          if (data.length > 0) {
            data.forEach((v) => {
              this.catalogueList.push({
                id: v.id,
                name: v.land_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          } else {
            Toast("没有更多了");
          }
        } else if (this.allList[this.navIndex].name == "厂房") {
          data = res.data.workslist.data;
          if (data.length > 0) {
            data.forEach((v) => {
              this.catalogueList.push({
                id: v.id,
                name: v.works_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          } else {
            Toast("没有更多了");
          }
        } else if (this.allList[this.navIndex].name == "项目") {
          data = res.data.itemslists.data;
          if (data.length > 0) {
            data.forEach((v) => {
              this.catalogueList.push({
                id: v.id,
                name: v.items_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          } else {
            Toast("没有更多了");
          }
        } else if (this.allList[this.navIndex].name == "楼宇") {
          data = res.data.buildlists.data;
          if (data.length > 0) {
            data.forEach((v) => {
              this.catalogueList.push({
                id: v.id,
                name: v.build_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          } else {
            Toast("没有更多了");
          }
        } else if (this.allList[this.navIndex].name == "众创") {
          data = res.data.masslists.data;
          if (data.length > 0) {
            data.forEach((v) => {
              this.catalogueList.push({
                id: v.id,
                name: v.mass_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          } else {
            Toast("没有更多了");
          }
        }else if (this.allList[this.navIndex].name == "名企") {
          data = res.data.famousen.data;
          if (data.length > 0) {
            data.forEach((v) => {
              this.catalogueList.push({
                id: v.id,
                name: v.enterprises_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          } else {
            Toast("没有更多了");
          }
        }else if (this.allList[this.navIndex].name == "商协会") {
          data = res.data.business.data;
          if (data.length > 0) {
            data.forEach((v) => {
              this.catalogueList.push({
                id: v.id,
                name: v.business_name,
                Latitude: v.Latitude,
                Longitude: v.Longitude,
              });
            });
          } else {
            Toast("没有更多了");
          }
        }
        //每次请求成功后关闭下拉刷新
        this.markers = [];
        this.catalogueList.forEach((v) => {
          this.markers.push({
            id: v.id,
            latitude: v.Latitude,
            longitude: v.Longitude,
            name: v.name,
          });
        });
      });
    },
    //去项目详情页
    toDetail(e, i) {
      let arr = [
        "Park",
        "Massif",
        "Workshop",
        "Project",
        "Building",
        "PopularEn",
        "FamousEnterprises",
        "BusinessAssociation",
      ];
      this.$router.push({
        name: arr[this.navIndex],
        params:{
          code:this.$route.params.code,
          id: this.toID
        }
      })
    },
    //去地块详情
    toProjectDetail(){
      this.$router.push({
        name: 'Massif',
        params:{
          code:this.$route.params.code,
          id: this.zancun.relation_landid
        }
      })
    },
    //去导航
    navigation() {
      let that = this;
      let locationPoint = {};
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          locationPoint = r.point;
          locationPoint.city = r.address.city;
          let url =
            "http://api.map.baidu.com/direction?origin=latlng:" +
            locationPoint.lat +
            "," +
            locationPoint.lng +
            "|name:我的位置&destination=latlng:" +
            that.zancun.Latitude +
            "," +
            that.zancun.Longitude +
            "|name:" +
            +that.zancun.name +
            "&origin_region=" +
            locationPoint.city +
            "&destination_region=" +
            that.region_infos.RegionName +
            "&mode=driving&output=html&src=webapp.baidu.openAPIdemo";
         window.location.href = url;
        } else {
          Toast("导航失败");
        }
      },
      );
      
    },
    //点击地块按钮
    massifClick(e) {
      this.massifIndex = -1;
      this.massifView = false;
      this.ispolygonsList(e.id);
    },
    //图形接口
    ispolygons() {
      land_map_list({
        region_code: this.params.code,
      }).then((res) => {
        this.land_map_lists = res.data.land_map_lists;
      });
    },
    //多边形
    ispolygonsList(e) {
      let arr = [];
      this.mapimg_lists = [];
      this.land_mapimg_lists = [];
      mapimg_list({
        land_map_id: e,
      }).then((res) => {
        Toast.clear();
        this.markers = [];
        arr = res.data.land_mapimg_lists;
        this.mapimg_lists = res.data.land_mapimg_lists;
        arr.forEach((v, index) => {
          //多边形
          if (v.drawing_type != 2) {
            //图形的中心点
            this.markers.push({
              id: v.id,
              latitude: v.Latitude,
              longitude: v.Longitude,
              name: v.drawing_name,
            });
            this.land_mapimg_lists.push({
              //经纬度数组
              points: [],
              //描边的宽度
              strokeWidth: 2,
              //描边的颜色,
              strokeColor: v.side_line,
              //填充颜色,
              fillColor: v.fill_color,
              //设置多边形 Z 轴数值
              zIndex: 1,
            });
            JSON.parse(v.drawing_img_coor).forEach((s) => {
              this.land_mapimg_lists[index].points.push({
                lat: s.lat,
                lng: s.lng,
              });
            });
          }
          //圆形
          else {
          }
        });
        this.polygons = this.land_mapimg_lists;
      });
    },

    //目录里item点击事件
    muClick(index) {
      this.xqIndex = index;
      this.navIndex = index;
      this.page = 1;
      this.isXZQU = false;
      setTimeout(() => {
        this.isXZQU = true;
      }, 300);
      this.catalogueList = [];
      this.loadList();
    },
    //每个item里面的列表点击事件
    muItemClick(v) {
      this.markers = [];
      this.polygons = [];
      this.massifIndex = -1;
      this.markers = [];
      this.markers.push({
        id: v.id,
        latitude: v.Latitude,
        longitude: v.Longitude,
        name: v.name,
      });
      this.flags = false;
      this.isXZQU = true;
    },
    //点击展示地块图形
    viewMassif(index) {
      if (this.massifIndex == index) {
        this.massifIndex = -1;
        this.massifFlag = false;
        this.massifView = false;
        this.polygons = [];
        this.markers = [];
        this.flags = false;
        this.isXZQU = true;
        return;
      }
      this.massifIndex = index;
      if (index == 0) {
        this.massifFlag = true;
        this.flags = false;
        this.isXZQU = false;
        Toast.loading({
          message: "加载中...",
          forbidClick: false,
        });
        this.ispolygonsList(this.land_map_lists[0].id);
      } else {
        this.flags = true;
        this.massifView = false;
        this.isXZQU = true;
      }
    },
    //标记点
    markerClick(e) {
      this.show = true;
      this.toID = e.id
      //点击图形
      if (this.mapimg_lists.length > 0) {
        this.mapimg_lists.forEach((v) => {
          if (v.id == e.id) {
            //选中的列表值
            this.zancun = {
              name: v.drawing_name,
              latitude: v.Latitude,
              longitude: v.Longitude,
              relation_landid: v.relation_landid
            };
          }
        });
      } else {
        //点击目录里面的标记点
        this.catalogueList.forEach((v) => {
          if (v.id == e.id) {
            //选中的列表值
            this.zancun = v;
          }
        });
      }
    },
    //显示更多
    more() {
      this.page++;
      this.loadList();
    },
    //地图首页接口
    mapInfo() {
      region_info({
        region_code: this.params.code,
      }).then((res) => {
        this.region_infos = res.data.region_infos;
        this.isXZQU = true;
        this.boundary.name = this.region_infos.RegionName;
        this.maxZoom = this.region_infos.MaxScale;
        this.minZoom = this.region_infos.MinScale;
        this.center.lng = this.region_infos.Longitude;
        this.center.lat = this.region_infos.Latitude;
      });
    },
  },
  created() {
    this.params = this.$route.params;
    this.loadList();
    this.ispolygons();
  },
};
</script>
<style lang="less" scoped>
.bm-view {
  width: 100%;
  height: calc(100vh - 44px);
}
/*地图头部导航栏*/
.topnav {
  margin: 8px 15px;
  display: flex;
  background-color: #fff;
  justify-content: center;
  position: fixed;
  top: 44px;
  left: 2%;
  right: 2%;
  .topnav_item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 0;

    .topnav_items {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      img {
        width: 1.6rem;
        height: 1.6rem;
        display: block;
      }

      .font {
        font-size: 0.8rem;
        margin-top: 3px;
      }
    }
  }
}
.actived {
  color: #35a3bd;
  border-bottom: 4px solid #35a3bd;
}
.activeds {
  background-color: #349dbb !important;
}
.left_btn {
  position: fixed;
  left: 3%;
  top: 50%;
  transform: translateY(-50%);

  .text {
    width: 3rem;
    height: 3rem;
    padding: 6px;
    color: #fff;
    background-color: #75babb;
    border-radius: 50%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-bottom: 10px;
    cursor: pointer;
  }
}
.collapse {
  width: 55%;
  height: 56%;
  position: fixed;
  bottom: 13%;
  left: 20%;
  background-color: #fff;
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 6px;

  .collapse_item {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);

    img {
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 10px;
    }
    span {
      position: absolute;
      right: 10%;
    }
    .collapse_item_list {
      display: flex;
      justify-content: left;
      padding: 16px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }

    .collapse_item_list:last-child {
      border-bottom: none;
    }
  }
}
.popup_center {
  background-color: #fff;
  padding: 20px;
  padding-bottom: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  .title {
    display: flex;
    justify-content: center;
    margin: 10px;
    font-size: 0.8rem;
  }

  .popup_btn {
    width: 40%;
    background-color: #349dbb;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
</style>